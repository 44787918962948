@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.App {
  height: 100%;
}

@layer utilities {
  .grid-bg {
    background-size: 16px 16px;
    /* A grey circle at the center, 1px wide */
    background-image: radial-gradient(
      circle,
      #d3d3d3 1px,
      rgba(0, 0, 0, 0) 1px
    );
  }
  .w-500 {
    width: 500px;
  }
  .mw-200 {
    min-width: 500px;
  }
  .h-600 {
    height: 600px;
  }
  .m-h-500 {
    max-height: 500px;
  }
  .gp-bg {
    background-color: rgba(0, 43, 54, 1);
  }
  .gp-bg-light {
    background-color: rgba(0, 42, 57, 1);
  }
  .styled-sb::-webkit-scrollbar {
    width: 8px;
  }
  .styled-sb-tb::-webkit-scrollbar-thumb {
    background: rgb(236, 72, 153);
    border-radius: 8px;
  }
  .trform {
    transition: transform 0.1s;
    height: transform 1s;
  }
  .proto-component:hover {
    border: 2px solid rgb(236, 72, 153);
  }
  .proto-component {
    border: 2px solid rgba(236, 72, 153, 0.1);
    -webkit-transition: border 90ms ease-out;
    -moz-transition: border 90ms ease-out;
    -o-transition: border 90ms ease-out;
    transition: border 90ms ease-out;
  }

  .bg-pink {
    background: rgb(236, 72, 153);
  }
  .modal-bg {
      background-color: rgba(0,0,0,0.5)
  }
  .proto-tag-container p {
    visibility: hidden;
    -webkit-transition: visibility 90ms ease-out;
    -moz-transition: visibility 90ms ease-out;
    -o-transition: visibility 90ms ease-out;
    transition: visibility 90ms ease-out;
  }
  .proto-tag-container:hover p {
    visibility: visible;
  }
  .proto-tag-container {
    padding: 6px;
  }
}
